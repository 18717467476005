<template>
    <v-card elevation="0" class="mr-4 mr-md-0 d-block text-left sale_card min-height400 margin-bottom25 bg-bg"
            color="white">
        <router-link
            class="d-flex flex-column text-decoration-none justify-space-between text-left min-height100 bg_white"
            :to="{name: 'projects.show', params: {project: data_sale_card.slug}}"
        >
            <v-img
                :src="data_sale_card.image_url"
                height="163px"
                max-height="163px"
                class="card-img bg_white">
                <div class="card-img__subtitle align-baseline">
                    <span class="bg_violet ">Паркинг в подарок</span>
                    <span class="bg_white ">Дом сдан!</span>
                </div>
            </v-img>
            <v-card-title class="font-weight-medium text-h5 black-color-text min-height70">
                {{ data_sale_card.name }}
            </v-card-title>
            <v-card-text class="pb-0">
                <p class="subtitle-1 description font-weight-regular pb-0 cut-text">
                    {{ data_sale_card.description | truncate(120, '...') }}
                </p>
            </v-card-text>
            <waiting-call-dialog class="ma-2 mt-auto align-end" :btn_location="'sale-card'" @click.stop.prevent/>
        </router-link>
    </v-card>
</template>

<script>
import WaitingCallDialog from "@/components/dialogs/WaitingCallDialog";

export default {
    name: "SaleCard",
    components: {WaitingCallDialog},
    props: ['data_sale_card'],
    data: function () {
        return {}
    },
    methods: {},
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.min-height70 {
    min-height: calc(100% - 332px);
}

.v-card__title {
    word-break: normal;
}

p.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000;
}

.card-img {
    position: relative;

    .card-img__subtitle {
        position: absolute;
        bottom: 12px;
        left: 12px;
        display: flex;
        flex-direction: column;

        .bg_violet {
            background: var(--main_color-base);
            color: #ffffff;
            font-size: 13px;
            padding: 5px 12px;
            display: inline-block;
            margin-bottom: 5px;
        }

        .bg_white {
            background: #ffffff;
            color: var(--green_color-base);
            font-size: 13px;
            padding: 5px 12px;
            display: inline-block;
        }
    }
}

.card {
    height: 370px;
    background: white !important;
}

.fz-24 {
    font-size: 24px !important;
    color: #000000;
}


.v-application .mt-auto.price {
    @media all and (max-width: 900px) {
        margin-top: auto;
        color: black;
    }
}

.carousel-circle .v-carousel--hide-delimiter-background .v-carousel__controls {
    @media all and (max-width: 900px) {
        background: whitesmoke !important;
    }
}

.black-color-text {
    color: black;
}

.bg_white {
    background: #ffffff !important;
}

.sale_card {
    display: block !important;
}

.min-height400 {
    @media all and (max-width: 900px) {
        min-height: 400px;
    }
}

.margin-bottom25 {
    @media all and (max-width: 900px) {
        margin-bottom: 25px;
    }
}
.v-application .white {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;

    @media all and (max-width: 900px) {
        background-color: var(--bg) !important;
        border-color: var(--bg) !important;
    }
}

.bg-bg {
    background: var(--bg) !important;

    @media all and (max-width: 900px) {
        background: white !important;
    }
}

.min-height100 {
    min-height: 420px;
}
</style>
